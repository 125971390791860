






































































































import BaseInput from "@/components/UI/BaseInput.vue";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import LoadingAddressObj from "@/components/order/addresses/LoadingAddressObj.vue";
import { required } from "@vuelidate/validators";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRef
} from "vue";
import useVuelidate from "@vuelidate/core";
import Dimensions from "@/components/order/places/Dimensions.vue";
import InputMask from "@/components/UI/InputMask.vue";
import PlaceOptions from "@/components/order/places/PlaceOptions.vue";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { getVatRateTypes } from "@/api/infoteka";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";

export default defineComponent({
  name: "PlaceItemLtl",
  components: {
    Dimensions,
    LoadingAddressObj,
    BaseInput,
    InputMask,
    PlaceOptions
  },
  props: {
    idx: { type: Number as PropType<number>, required: true },
    place: {
      type: Object as PropType<PlaceItemInterface>,
      required: true
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: [
    "remove",
    "addUnloadingAddress",
    "addLoadingContact",
    "removeLoadingContact",
    "addUnloadingContact",
    "removeUnloadingContact",
    "update:assessed-value",
    "update:place-dimension"
  ],
  setup(props) {
    const place = toRef(props, "place");
    const v$ = useVuelidate(
      {
        loadingAddresses: {
          required
        },
        deliveryPrice: {
          required
        }
      },
      place
    );
    const taxesTypes = ref<ListItemInterface[]>([]);
    const loadTaxesTypes = async () => {
      taxesTypes.value = await getVatRateTypes();
    };

    onMounted(() => {
      loadTaxesTypes();
    });

    return {
      v$,
      taxesTypes,
      tasksCount: computed<number>(() => {
        let tasksCount = 0;

        place.value.loadingAddresses.forEach(
          (loadingAddress: LoadingAddressObjectInterface) => {
            tasksCount += loadingAddress.unloadingAddresses.length;
          }
        );

        return tasksCount;
      })
    };
  }
});
